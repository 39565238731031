import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href="https://github.com/adeutou" target="_blank">
          <BsGithub />
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/albert-deutou-194569b5/" target="_blank">
          <BsLinkedin />
        </a>        
      </div>
      
    </div>
    
  )
}

export default SocialMedia